<app-accordion-item
  [title]="title + '(' + total + ')'"
  [itemId]="id"
  [open]="false">
  <div class="row">
    <app-list-page-header
      (searchRequest)="requestSearch($event)">
    </app-list-page-header>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-basic-table
        [isPaginationEnabled]="true"
        [paginationConfig]="pageConf"
        (pageChangeRequest)="requestPageChange()"
        [data]="items"
        [columns]="coloums"
        [isRawSpan]="isRawSpan">
      </app-basic-table>
    </div>
  </div>
</app-accordion-item>
